@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';
@import '../../../stylesheets/admin/variables.postcss';

.Pagination {
  margin-top: 0.5rem;
  margin-bottom: -0.5rem;
  text-align: center;
  list-style: none;
  user-select: none;
  font-size: 1.25rem;
  padding: 0.5rem;
  border-radius: $radius;

  @mixin above $medium {
    font-size: 1rem;
  }
}

.Pagination li {
  margin: 0 0.25rem;
  display: inline-block;

  &.Pagination_disabled {
    display: none;
  }
}

li.Pagination_break {
  margin: 0;
}

.Pagination a {
  cursor: pointer;
  &:active, &:focus {
    outline: none;
  }
}

.Pagination_selected {
  a {
    color: $black;
    font-weight: 900;
  }
}

.Pagination_previous, .Pagination_next {
  font-weight: 600;
  font-weight: 500;
}

:global(.admin-layout) {

  .Pagination {
    a {
      color: black;

      &:hover {
        color: $text-light;
      }
    }
  }

  }